import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../redux/slices/authSlice';
import styles from './Login.module.css'; // Importing as a CSS module
import googleIcon from '../assets/google.png';
import lightLogo from '../assets/lightlogo.svg';

// Import the banner images
import banner1 from '../assets/banner1.jpg';
import banner2 from '../assets/banner2.jpg';
import banner3 from '../assets/banner3.jpg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currentBanner, setCurrentBanner] = useState(0); // State to track the current banner index
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { loading, error, token } = useSelector((state) => state.auth);
  const banners = [banner1, banner2, banner3]; // Array of banner images
  
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };
  
  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  // Rotate banner every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000); // 3 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [banners.length]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        {/* Left Panel for Rotating Banner */}
        <div className={styles.leftPanel}>
          <div
            className={styles.skeletonImage}
            style={{ backgroundImage: `url(${banners[currentBanner]})` }} // Dynamic banner
          ></div>
        </div>
        
        {/* Right Panel with Login Form */}
        <div className={styles.rightPanel}>
          <div className={styles.loginCard}>
            {/* New Logo */}
            <div className={styles.newLogo}>
              <img src={lightLogo} alt="Company Logo" />
            </div>
            
            <h2>Login to your Account</h2>
            
            <button className={styles.googleBtn}>
              <img src={googleIcon} alt="Google Logo" className={styles.googleLogo} />
              Continue with Google
            </button>
            
            <p className={styles.orSignin}>or Sign in with Email</p>
            
            <form onSubmit={handleSubmit}>
              <input 
                type="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
                className={styles.input}
              />
              <input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
                className={styles.input}
              />
              
              <div className={styles.checkboxRow}>
                <label>
                  <input type="checkbox" /> Remember Me
                </label>
                <a href="/forgot-password" className={styles.forgotPassword}>Forgot Password?</a>
              </div>
              
              <button type="submit" className={styles.loginBtn} disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </form>
            
            <p className={styles.registerLink}>
              Don’t have an account? <a href="/register">Create an account</a>
            </p>
            {error && <p className={styles.feedbackMessage}>{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
